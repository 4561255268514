import dispatchEvent from './dispatch-event';
// import debugHelpers from './debug-helpers.js';

// debugHelpers.adLoading();
// debugHelpers.clsDebug();

const burger = document.querySelector('.hamburger-btn');
const leftNav = document.getElementById('left-nav');
const main = document.getElementById('main');
const closeButton = document.getElementById('left-nav-close');
const isWebview = document.body.classList.contains('webview'); // webviews don't have nav
const isNewsletterEmbed = document.body.classList.contains('newsletter--oneclick');
const hasNav = !isWebview && !isNewsletterEmbed;
const dialog = document.querySelector('[role="dialog"]');
const firstFocusableElement = (hasNav && dialog) ? dialog.querySelector('.first-focusable-element') : null;
const lastFocusableElement = (hasNav && dialog) ? dialog.querySelector('.last-focusable-element') : null;
const leaderboardFlex = document.querySelector('.ad-leaderboard-flex');
const hasLeaderboardFlexAd = !!leaderboardFlex;
const desktopHeader = document.querySelector('.desktop-sticky-nav');
const LEADERBOARD_MIN_HEIGHT_DESKTOP = 85;
const STICKING_TIME = 5000;
const isDesktopNav = !!desktopHeader && window.HP?.params?.device === 'desktop'; // needs checks bc this gets included in static nav js :/

const removeTransparentAfterSticking = () => {
  desktopHeader.classList.remove('transparent');
  desktopHeader.removeEventListener('transitionend', removeTransparentAfterSticking);
};

const toggleTransparentDuringSticking = () => {
  if (window.scrollY > 0) {
    desktopHeader.classList.add('transparent');
  } else {
    desktopHeader.classList.remove('transparent');
  }
};

const queueLeaderboardUnstickEvent = (caller = 'unknown') => {
  if (isDesktopNav && caller === 'showRecirc') {
    window.removeEventListener('scroll', toggleTransparentDuringSticking);
    desktopHeader.classList.remove('transparent');
    desktopHeader.addEventListener('transitionend', removeTransparentAfterSticking);
  }
  setTimeout(() => {
    dispatchEvent('unstick-leaderboard');
  }, STICKING_TIME);
};

const watchLeaderboardDesktop = () => {
  const recircAdContainer = document.querySelector('.top-ad-recirc');
  if (recircAdContainer) {
    const leaderboardAd = recircAdContainer.querySelector('.ad-leaderboard-flex #ad_leaderboard_flex');
    const recirc = recircAdContainer.querySelector('.zone--ad-recirc');
    recirc.classList.add('js-cet-subunit');
    let recircCountdown;
    // let resizeObserver;

    const showRecirc = ({ didExpire = false, showFull = false } = {}) => {
      if (didExpire) {
        // eslint-disable-next-line no-console
        console.debug('leaderboardAd monitor expired');
      }
      if (showFull) {
        // eslint-disable-next-line no-console
        console.debug('forcing full recirc!!!!');
      }

      // TODO: Must leave Observer connected for duration for slow connections otherwise ad may load after recirc is shown. One option is to tap into to GTM slotRenderEnded event on `ad-top` slot to trigger this but must work for all editions :/
      // wait 10s before we stop adjusting topAdRecirc to the ad size as iframe can bounce around in size
      // setTimeout(() => resizeObserver.disconnect(), 10000);

      const height = showFull ? 0 : leaderboardAd?.offsetHeight;
      // no recirc + flex container
      if (height >= 251) {
        recircAdContainer.classList.remove('recirc--half-display', 'recirc--no-display', 'recirc--full-display');
        recircAdContainer.classList.add('recirc--flex-display');
      // no recirc
      } else if (height > 120 && height < 251) {
        const recircOnly = document.querySelector('.recirc-ad-module');
        recircOnly?.remove();
        recircAdContainer.classList.remove('recirc--full-display', 'recirc--half-display');
        recircAdContainer.classList.add('recirc--no-display');
      // text only recirc
      } else if (height <= 120 && height > 0) {
        recircAdContainer.classList.remove('recirc--full-display', 'recirc--no-display');
        recircAdContainer.classList.add('recirc--half-display');
      // full image recirc (if tiny ad or no ad)
      } else {
        recircAdContainer.classList.remove('recirc--half-display', 'recirc--no-display');
        recircAdContainer.classList.add('recirc--full-display');
      }
      recircAdContainer.classList.remove('ad-fetching');
      queueLeaderboardUnstickEvent('showRecirc');
    };

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        clearTimeout(recircCountdown); // clear last countdown to start new one below
        const leaderboardAdIframe = leaderboardAd.querySelector('iframe');
        const adIframeHasLoaded = (leaderboardAdIframe?.offsetHeight > LEADERBOARD_MIN_HEIGHT_DESKTOP);
        if (adIframeHasLoaded) {
          // iframe has loaded, which means full height of ad has stabilized; can trigger
          // the display of the recirc even though timeout has not finished.
          showRecirc();
        }
        // re-start 5s countdown to show recirc, though recirc will be shown earlier once iframe has loaded
        recircCountdown = setTimeout(() => showRecirc({ didExpire: true }), 5000);
      });
    });
    if (leaderboardAd) {
      resizeObserver.observe(leaderboardAd);
    } else {
      showRecirc({ didExpire: true, showFull: true });
    }
  }
};

// Stick desktopHeader at page load until leaderboard ad has had height for 5 seconds, then unstick
const unstickLeaderboard = () => {
  if (hasNav) {
    const leaderboardFlexTempSticky = document.querySelector('.ad-leaderboard-flex.temp-sticky');
    if (isDesktopNav) {
      desktopHeader.classList.remove('sticking');
      const desktopHeaderHeight = desktopHeader.offsetHeight;
      desktopHeader.style.top = `-${desktopHeaderHeight}px`;
    } else if (leaderboardFlexTempSticky) {
      const leaderboardFlexTempStickyHeight = leaderboardFlexTempSticky.offsetHeight;
      leaderboardFlexTempSticky.classList.add('unstick');
      leaderboardFlexTempSticky.style.top = `-${leaderboardFlexTempStickyHeight}px`;
    }
  }
};

const closeDialog = () => {
  document.body.classList.remove('hamburger-open');
  leftNav.classList.add('transition-active');
};

const addNavListener = () => {
  document.body.addEventListener('click', (event) => {
    if (document.body.classList.contains('hamburger-open')) {
      if (!event.target.closest('.left-nav') || event.target.closest('.left-nav__close')) {
        closeDialog();
        if (main) {
          // main is not present when nav is rendered on static templates
          main.removeAttribute('aria-hidden');
        }
      }
    } else if (event.target.closest('.hamburger')) {
      document.body.classList.add('hamburger-open');

      if (main) {
        // main is not present when nav is rendered on static templates
        main.setAttribute('aria-hidden', true);
      }
    }
  });

  if (dialog) {
    dialog.addEventListener('keydown', (e) => {
      if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastFocusableElement.focus();
      } else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
      if (e.key === 'Escape' && document.body.classList.contains('hamburger-open')) {
        closeDialog();
      }
    });
  }

  document.body.addEventListener('transitionend', (e) => {
    if (document.body.classList.contains('hamburger-open')) {
      closeButton.focus();
    }
    if (e.propertyName === 'left' && leftNav.classList.contains('transition-active')) {
      leftNav.classList.remove('transition-active');
      burger.focus();
    }
  });
};

// All editions on desktop:
if (hasNav && hasLeaderboardFlexAd && isDesktopNav) {
  window.addEventListener('scroll', toggleTransparentDuringSticking);
  document.addEventListener('unstick-leaderboard', unstickLeaderboard, { once: true });
  watchLeaderboardDesktop();
} else if (hasNav && hasLeaderboardFlexAd && !isDesktopNav) {
  const fixedMobileLeaderboard = leaderboardFlex.classList.contains('js-always-sticky');
  // US & UK MW:
  if (fixedMobileLeaderboard) {
    const dismissCaret = document.querySelector('.ad-leaderboard-flex__dismiss');
    const updateCaret = () => {
      if (window.scrollY >= 40) {
        dismissCaret.classList.add('show');
      } else {
        dismissCaret.classList.remove('show');
        leaderboardFlex.classList.remove('sticky-dismissed');
      }
    };
    window.addEventListener('scroll', updateCaret);
    dismissCaret.addEventListener('click', () => {
      leaderboardFlex.classList.add('sticky-dismissed');
    });
    // JP & GR MW:
  } else {
    // this will add class to unstick leaderboard
    document.addEventListener('unstick-leaderboard', unstickLeaderboard);

    // this will queue the unstick event 5s after leaderboard is treated
    document.addEventListener('leaderboard-treated', () => queueLeaderboardUnstickEvent('else'));
  }
}

export default () => {
  if (document.readyState !== 'loading') {
    // This file can be called asynchronously after DOM loaded event.
    // Attach event handler right away when this DOM loaded event has already been triggered.
    addNavListener();
  } else {
    document.addEventListener('DOMContentLoaded', addNavListener, { once: true });
  }
};
